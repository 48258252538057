<template>
  <div class="top_select">
    <div>
      <el-select
        @change="invoiceTypeChange"
        filterable
        v-model="listQuery.invoiceType"
        placeholder="请选择发票类型"
        size="small"
        style="width: 150px; margin-left: 0"
        clearable
        multiple
        collapse-tags
        class="selects"
      >
        <el-option
          v-for="item in optionsIn"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-input
        v-model="listQuery.fphm"
        placeholder="请输入销方名称/发票号码"
        @keyup.enter="getList()"
        style="width: 150px"
        size="small"
        clearable
      ></el-input>
      <el-button
        style="margin-right: 5px"
        size="small"
        @click="getList()"
        type="primary"
        icon="Search"
      >
        搜索
      </el-button>
      <span style="margin-left: 6px; color: red; font-size: 12px"
        >温馨提示：本栏目用于存储暂不入账的票据</span
      >
    </div>
    <div v-if="orgId != 1">
      <el-button
        size="small"
        type="primary"
        icon="DArrowLeft"
        @click="moveIn"
        plain
        :loading="moveLoading"
        >转移到进项发票</el-button
      >
      <el-button
        size="small"
        type="danger"
        @click="delALLInvoiceWare()"
        style="margin-left: 10px"
        :loading="loading"
        plain
        icon="Delete"
      >
        删除
      </el-button>
    </div>
  </div>

  <div class="top_title clearfix">
    <div class="top-nav right_box">
      <el-row>
        <el-col :span="3">
          <div style="display: flex; align-items: center; padding-left: 10px">
            <input
              type="checkbox"
              :checked="isAllSelected"
              :style="{ marginLeft: 0 }"
              @click="allSelect"
            />
            <div class="box-content" style="border-left: none; width: 80%">
              业务类型
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="box-content">开票项目</div>
        </el-col>
        <el-col :span="6">
          <div class="box-content">补充项目</div>
        </el-col>
        <el-col :span="2">
          <div class="box-content">数量</div>
        </el-col>
        <el-col :span="2">
          <div class="box-content">
            金额
            <el-icon class="opera_icon" @click="sortChange"><DCaret /></el-icon>
          </div>
        </el-col>
        <el-col :span="1">
          <div class="box-content">税率</div>
        </el-col>
        <el-col :span="2">
          <div class="box-content">税额</div>
        </el-col>
        <el-col :span="2">
          <div class="box-content">价税合计</div>
        </el-col>
      </el-row>
    </div>
  </div>

  <div v-loading="loadingBig">
    <el-scrollbar :style="{ height: contentStyleObj }">
      <el-empty :image-size="150" v-if="list.length == 0"></el-empty>
      <div
        class="content_center clearfix"
        style="margin-bottom: 10px"
        v-for="(item, index) in list"
        :key="index"
      >
        <div class="content-nav right_box fl">
          <el-row class="content-top">
            <el-col :span="24" class="box-content clearfix">
              <ul class="top_font clearfix">
                <li
                  style="padding-left: 10px"
                  @click="singleSelect(item, index)"
                >
                  <input
                    type="checkbox"
                    :checked="item.checked"
                    :style="{ marginLeft: 0, marginTop: '10px' }"
                  />
                </li>
                <li
                  style="width: 42px"
                  v-if="item.invoiceType != '2' && item.invoiceType != '5'"
                >
                  <span
                    :style="{ color: item.rzzt == 1 ? '#008000' : '#e6a23c' }"
                  >
                    {{ item.rzzt == 1 ? "已认证" : "未认证" }}
                  </span>
                </li>
                <li style="width: 75px; padding-left: 0">
                  <span
                    :style="{
                      color: ['1', '4'].includes(item.invoiceType)
                        ? 'green'
                        : '#d56400',
                    }"
                  >
                    {{ filterInvoiceType(item.invoiceType) }}
                  </span>
                </li>
                <el-tooltip
                  effect="dark"
                  :content="item.fphm"
                  placement="top"
                  :disabled="!item.fphm"
                >
                  <li style="width: 215px" class="ccyc">
                    发票号码:
                    <span
                      :style="{
                        color: ['1', '4'].includes(item.invoiceType)
                          ? 'green'
                          : '#d56400',
                      }"
                    >
                      {{ item.fphm }}
                    </span>
                  </li>
                </el-tooltip>

                <el-tooltip
                  effect="dark"
                  :content="item.corpName"
                  placement="top"
                  :disabled="!item.corpName"
                >
                  <li style="width: 220px" class="ccyc">
                    销方:
                    <span>{{ item.corpName }}</span>
                  </li>
                </el-tooltip>
                <li style="width: 136px">
                  开票日期:
                  <span>{{ $parseTime(item.invoiceDate, "{y}-{m}-{d}") }}</span>
                </li>
                <li style="width: 100px" class="ccyc">
                  结算状态:
                  <span
                    :style="{
                      color: item.settlementStatus == 1 ? 'green' : 'red',
                    }"
                  >
                    {{ item.settlementStatus == 1 ? "已结算" : "未结算" }}
                  </span>
                </li>
                <li style="width: 92px">
                  账期:
                  <span> {{ item.period }}</span>
                </li>
                <li v-if="item.autoLabel || item.autoOtherName">
                  <el-tooltip effect="dark" placement="top">
                    <template #content>
                      <p>标签：{{ item.autoLabel }}</p>
                      <p>报销方名称：{{ item.autoOtherName }}</p>
                    </template>
                    <span
                      class="xgmStyle"
                      style="font-size: 11px; cursor: pointer; padding: 0px"
                      >标注</span
                    >
                  </el-tooltip>
                </li>
              </ul>
              <div class="top_right_btns">
                <el-tooltip content="删除" placement="top" effect="dark">
                  <i
                    @click="delInvoiceWare(item)"
                    class="iconfont icon-shanchu"
                  ></i>
                </el-tooltip>
              </div>
            </el-col>
          </el-row>
          <div class="center_big clearfix">
            <el-button
              class="fold_icon"
              v-if="item.items?.length > 2 && !item.showStatus"
              @click="item.showStatus = true"
              size="small"
              >展开全部 <el-icon><ArrowDown /></el-icon
            ></el-button>
            <el-button
              class="fold_icon"
              v-if="item.showStatus"
              @click="item.showStatus = false"
              size="small"
              >收起全部 <el-icon><ArrowUp /></el-icon
            ></el-button>
            <div
              class="content"
              v-for="(itemw, index2) in item.items"
              :key="index2"
            >
              <el-row
                class="for-content"
                v-if="(index2 < 2 && !item.showStatus) || item.showStatus"
              >
                <el-col :span="3">
                  <div class="box-content">{{ itemw.businessName }}</div>
                </el-col>
                <el-col :span="6">
                  <el-tooltip class="item" effect="dark" placement="top">
                    <template #content>
                      <div>
                        {{ itemw.name
                        }}{{ itemw.remark ? "(" + itemw.remark + ")" : "" }}
                      </div>
                    </template>
                    <div class="box-content ccyc" style="text-align: left">
                      {{ itemw.name
                      }}{{ itemw.remark ? "(" + itemw.remark + ")" : "" }}
                    </div>
                  </el-tooltip>
                </el-col>
                <el-col :span="6">
                  <div class="box-content ccyc" style="text-align: left">
                    {{ itemw.itemName }}
                  </div>
                </el-col>
                <el-col :span="2">
                  <div class="box-content">{{ itemw.count }}</div>
                </el-col>
                <el-col :span="2">
                  <div class="box-content">{{ itemw.amount }}</div>
                </el-col>
                <el-col :span="1">
                  <div class="box-content">{{ itemw.taxRate }}</div>
                </el-col>
                <el-col :span="2">
                  <div class="box-content">{{ itemw.tax }}</div>
                </el-col>
                <el-col :span="2">
                  <div class="box-content">{{ itemw.total }}</div>
                </el-col>
              </el-row>
            </div>
          </div>
          <el-row class="bottom-content">
            <el-col :span="17">
              <div class="box-content">合计</div>
            </el-col>

            <el-col :span="2">
              <div class="box-content">{{ item.sumAmount }}</div>
            </el-col>
            <el-col :span="1">
              <div class="box-content"></div>
            </el-col>
            <el-col :span="2">
              <div class="box-content">{{ item.sumTax }}</div>
            </el-col>
            <el-col :span="2">
              <div class="box-content">{{ item.sumTotal }}</div>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-scrollbar>
  </div>
  <div class="pagination">
    <qzf-pagination
      v-show="total > 0"
      :total="total"
      v-model:page="listQuery.page"
      v-model:limit="listQuery.limit"
      @pagination="getList"
    />
  </div>
</template>

<script setup>
import {
  inInvoiceList,
  inInvoiceMigrateWare,
  delInInvoiceWare,
  getperiodStatus
} from "@/api/invoice";
import { ref, onMounted, getCurrentInstance, computed } from "vue";
import { IN_INVOICE_OPTION } from "@/utils/commonData";
const { proxy } = getCurrentInstance();
const emit = defineEmits();
const isAllSelected = computed({
  get() {
    if (list.value.length == 0) return false;
    return list.value.every((item) => item.checked);
  },
});
const props = defineProps({
  comId: {
    type: Number,
    default: 0,
  },
  period: {
    type: String,
    default: "",
  },
});
const contentStyleObj = ref({});
const loadingBig = ref(false);
const moveLoading = ref(false);
const loading = ref(false);
const list = ref([]);
const total = ref(0);
const selects = ref([]);
const orgId = ref(0);
const optionsIn = IN_INVOICE_OPTION;
const listQuery = ref({
  page: 1,
  limit: 20,
  taxRate: 1,
  rq: null,
  ssflbm: "",
  upload: 0,
  voucher: 0,
  js: 0,
  fphm: "",
  corpName: "",
  ware: true,
  rzzt: 0,
  dpzt: 2,
  negative: 0,
  remark: "",
  invoiceType: [],
  name: "",
  itemName: "",
  voucherNo: "",
  hasAutoLabel: null,
  comId: props.comId,
  period: props.period,
});
onMounted(() => {
  contentStyleObj.value = proxy.$getHeight(300);
  orgId.value = proxy.$store.getters["user/user"].orgId;
  getList();
});
const getList = () => {
  loadingBig.value = true;
  inInvoiceList(listQuery.value).then((res) => {
    loadingBig.value = false;
    if (res.data.msg == "success") {
      list.value = res.data.data.list ? res.data.data.list : [];
      total.value = res.data.data.total;
      selects.value = [];
    }
  });
};
//转移到进项
const moveIn = () => {
  if (selects.value.length == 0) {
    proxy.$qzfMessage("请至少选择一条", 1);
    return;
  }
  moveLoading.value = true;
  let ids = [];
  selects.value.map((v) => {
    ids.push(v.id);
  });
  getperiodStatus({
    periods: [props.period],
    comId: props.comId
  }).then(res=>{
    if(res.data.data.settled){//判断是不是结账 未结账不能转
      moveLoading.value = false;
      proxy.$qzfMessage('本期已结账，不能转移到进项发票！',2)
      return
    }else{
      inInvoiceMigrateWare({
        ids: ids,
        type: "in",
        period: props.period,
      }).then((res) => {
        moveLoading.value = false;
        if (res.data.msg == "success") {
          proxy.$qzfMessage("迁移成功");
          getList();
          emit("success");
        }
      });
    }
  })
 
};
//单独删除
const delInvoiceWare = (row) => {
  invoiceWareDel([{ id: row.id }]);
};
//批量删除
const delALLInvoiceWare = () => {
  if (selects.value.length == 0) {
    proxy.$qzfMessage("请至少选择一条", 1);
    return;
  }
  let delSelects = [];
  selects.value.map((v) => {
    delSelects.push({
      id: v.id,
    });
  });
  invoiceWareDel(delSelects);
};
const invoiceWareDel = (ids) => {
  proxy
    .$confirm("确认删除发票吗", "提示", {
      confirmButtonText: " 确定",
      cancelButtonText: "取消",
      type: "warning",
      confirmButtonClass: "iconfont icon-shanchu",
    })
    .then(() => {
      loadingBig.value = true;
      loading.value = true;
      delInInvoiceWare(ids).then((res) => {
        loadingBig.value = false;
        loading.value = false;
        if (res.data.msg == "success") {
          proxy.$qzfMessage("删除成功");
          getList();
        }
      });
    });
};
//单选
const singleSelect = (row, index) => {
  row.checked = !row.checked;
  list.value.splice(index, 1, row);
  screenChecked();
};
//全选、全不选
const allSelect = () => {
  let checked = true;
  // 全选
  if (isAllSelected.value) {
    checked = false;
  }
  list.value = list.value.map((el) => {
    el.checked = checked;
    return el;
  });
  screenChecked();
};
const screenChecked = () => {
  selects.value = list.value.filter((el) => el.checked);
};
const invoiceTypeChange = () => {
  let arr = listQuery.value.invoiceType;
  let arrLength = listQuery.value.invoiceType.length;
  if (
    !arrLength ||
    (arrLength == 1 && (arr[0] == "2" || arr[0] == "5")) ||
    (arrLength == 2 && arr.includes("2") && arr.includes("5"))
  ) {
    listQuery.value.rzzt = 0;
  }

  getList();
};
const filterInvoiceType = (type) => {
  if (type == "1") {
    return "（专）";
  } else if (type == "2") {
    return "（普）";
  } else if (type == "3") {
    return "（农(9%)）";
  } else if (type == "4") {
    return "（电专）";
  } else if (type == "5") {
    return "（电普）";
  } else if (type == "6") {
    return "（海关）";
  } else if (type == "7") {
    return "（其他）";
  } else if (type == "8") {
    return "（农(10%)）";
  }
};
defineExpose({
  getList,
});
</script>

<style scoped lang="scss">
.top_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.top-nav {
  height: 36px;
  border: 1px solid #b9b9b9;
  background: var(--themeColor, #17a2b8);
  color: #fff;
  margin-bottom: 10px;
  font-weight: 500;
  position: relative;
  .box-content {
    box-sizing: border-box;
    text-align: center;
    border-left: 1px solid #b9b9b9;
    line-height: 34px;
    font-size: 13px;
    height: 34px;
  }
}
.right_box {
  float: left;
  width: 100%;
}
.content-nav {
  line-height: 36px;
  border: 1px solid #b9b9b9;
  .content-top {
    background: var(--themeColorLeftMenu, #ecf7f9);
    .box-content {
      box-sizing: border-box;
      text-align: center;
      line-height: 36px;
      height: 36px;
      font-size: 13px;
      border-bottom: 1px solid #b9b9b9;
    }
    .box-content:first-child(1) {
      border-left: none;
    }
  }
  .for-content {
    .box-content {
      box-sizing: border-box;
      border-left: 1px solid #b9b9b9;
      text-align: center;
      line-height: 36px;
      height: 36px;
      font-size: 13px;
      border-bottom: 1px solid #b9b9b9;
      padding: 0 10px;
      color: #212529;
    }
    .el-col:nth-child(9) .box-content {
      border-right: 1px solid #b9b9b9;
    }
    .el-col:nth-child(1) .box-content {
      border-left: none;
    }
  }
  .bottom-content {
    .box-content {
      box-sizing: border-box;
      border-left: 1px solid #b9b9b9;
      text-align: center;
      line-height: 36px;
      height: 36px;
      font-size: 13px;
      color: #212529;
    }
    .el-col:nth-child(1) .box-content {
      border-left: none;
    }
    .el-col:nth-child(7) .box-content {
      border-right: 1px solid #b9b9b9;
    }
  }
}
.top_font {
  float: left;
  width: 87%;
  margin: 0 auto;
  li {
    font-size: 13px;
    color: #212529;
    float: left;
    text-align: left;
    padding-left: 5px;
  }
}
.top_right_btns {
  float: right;
  margin-right: 5px;
  position: relative;
  i {
    margin: 0 3px;
    cursor: pointer;
    font-size: 17px;
    line-height: 24px;
    color: #fe696a;
  }
}
.ccyc {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.selects :deep(.tags-select-input) {
  height: 24px;
}
.selects :deep(.el-select__tags) {
  height: 24px;
  white-space: nowrap;
  overflow: hidden;
  flex-wrap: nowrap;
}
.selects :deep(.el-select__tags-text) {
  display: inline-block;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.center_big {
  position: relative;
}
.fold_icon {
  position: absolute;
  left: 50%;
  bottom: -10px;
  z-index: 1;
  padding: 0 10px;
  border-radius: 14px;
  font-size: 11px !important;
  height: 20px;
  line-height: 20px;
}
</style>
