<template>
  <el-drawer
    class="elDrawerDetail"
    :title="comName"
    v-model="drawer"
    :direction="direction"
    :before-close="handleClose"
    destroy-on-close
    size="77%"
  >
    <el-tabs v-model="activeName"  @tab-click="handleClick" type="border-card">
      <el-tab-pane label="正常发票" name="first">
        <div style="margin-bottom:10px">
          <el-date-picker
            class="rangeDate"
            style="width:200px;position:relative;top:3px;margin-right:10px"
            size="small"
            v-model="value1"
            type="monthrange"
            range-separator="至"
            start-placeholder="开始账期"
            end-placeholder="结束账期"
            @change="changeMonth"
            value-format="YYYYMM"
          >
          </el-date-picker>
          <el-radio-group size="small" @change="changePeriod" v-model="detailPeriod" style="position:relative;top:-4px;margin-right:10px">
            <el-radio-button label="month">本账期</el-radio-button>
            <el-radio-button label="season">本季度</el-radio-button>  
            <el-radio-button label="year">本年度</el-radio-button>  
          </el-radio-group>
          <el-input style="width:150px" size="small" placeholder="发票号码" v-model="listQuery.fphm"></el-input>
          <el-button size="small" type="primary" @click="getList()">搜索</el-button>
          <el-select @change="getList" filterable v-model="listQuery.invoiceType" placeholder="选择发票类型" size="small" style="width:200px" clearable>
            <el-option
              v-for="item in typeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          
        </div>
        <div class="top_select">
          <div>
            <el-radio-group v-model="listQuery.types" size="small" @change="changeDetailType">
              <el-radio-button label="all">全部({{ invoiceNum.allNum }})</el-radio-button>
              <el-radio-button label="out">销项发票({{ invoiceNum.outNum }})</el-radio-button>
              <el-radio-button label="in">进项发票({{ invoiceNum.inNum }})</el-radio-button>  
              <el-radio-button label="1">已认证({{ invoiceNum.authNum }})</el-radio-button>
              <el-radio-button label="0">未认证({{ invoiceNum.nopAuthNum }})</el-radio-button>
            </el-radio-group>
          </div>
          <div>
            <el-tooltip :disabled="showTooltipStatus" content="发票已确认，不可添加其他费用" placement="top">
              <el-button size="small" type="primary" plain @click="addFee"  :disabled="[3,6,7,8].includes(comInfo.djqr_invoice_status)" icon="Plus">添加其他费用</el-button>
            </el-tooltip>
            <el-tooltip :disabled="showTooltipStatus" content="发票已确认，不可添加无票收入" placement="top">
              <el-button size="small" type="primary" plain @click="addNone" :disabled="[3,6,7,8].includes(comInfo.djqr_invoice_status)" icon="Plus">添加无票收入</el-button>
            </el-tooltip>
            <el-tooltip :disabled="showTooltipStatus" content="发票已确认，不可转移到发票仓库" placement="top" v-if="orgId !=1">
              <el-button size="small" type="primary" plain @click="moveWare" icon="Promotion" :disabled="[3,6,7,8].includes(comInfo.djqr_invoice_status)">转移到发票仓库</el-button>
            </el-tooltip>
            <el-tooltip :disabled="comInfo.djqr_invoice_status == 2" content="发票不是待确认状态，不可确认" placement="top" v-if="showStatus">
              <el-button size="small" type="success" plain @click="batchConfirm"  :disabled="comInfo.djqr_invoice_status != 2" icon="Checked">单据确认</el-button>
            </el-tooltip>
            <el-tooltip :disabled="showTooltipStatus" content="发票已确认，不可单据标注" placement="top" v-if="orgId !=1">
              <el-button size="small" type="primary" plain @click="batchSet" :disabled="[3,6,7,8].includes(comInfo.djqr_invoice_status)" icon="Finished">单据标注</el-button>
            </el-tooltip>
            <el-button size="small" v-if="orgId !=1" type="success" @click="exportDetail" icon="Folder" plain>导出</el-button>
          </div>
        </div>
        <el-table :data="showList" border :height="contentStyleObj" style="width: 100%" stripe v-loading="loading" @selection-change="handleSelectionChange">
          <template #empty>
            <el-empty :image-size="150" description="没有数据"></el-empty>
          </template>
          <el-table-column type="selection" align="center" width="55" />
          <el-table-column label="进/销项" width="65"> 
            <template #default="scope">
              <span v-if="scope.row.types == 'in'">进项</span>
              <span v-if="scope.row.types == 'out'">销项</span>
            </template>
          </el-table-column>
          <el-table-column prop="invoiceName" label="发票类型" width="180"> </el-table-column>
          <el-table-column label="发票号码" width="170"> 
            <template #default="scope">
              <span>{{ scope.row.invoiceNum}}</span>
            </template>
          </el-table-column>
          <el-table-column label="认证状态" width="85"> 
            <template #default="scope">
              <div v-if="scope.row.types == 'in' && scope.row.invoiceType != '2' && scope.row.invoiceType != '5'">
                <span v-if="scope.row.authStatus == 1">已认证</span>
                <span v-else>未认证</span>
              </div>
              <div v-else></div>
            </template>
          </el-table-column>
          <el-table-column prop="invoiceCreateTime" label="开票日期" width="90">
            <template #default="scope">
              <span>{{ $parseTime(scope.row.invoiceCreateTime, "{y}-{m}-{d}")}}</span>
            </template>
          </el-table-column>
          <el-table-column label="购销方单位" min-width="240"> 
            <template #default="scope">
              <span>{{ scope.row.corpName}}</span>
            </template>
          </el-table-column>
          <el-table-column label="开票项目" min-width="150"> 
            <template #default="scope">
              <el-tooltip
                effect="dark"
                placement="top-start"
              >
                <template #content>
                  <div style="max-width:500px">{{scope.row.name?.replaceAll('#|#', "，") }}</div>
                </template>
              <span>{{ scope.row.name?.replaceAll('#|#', "，")}}</span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column  label="标签" width="140" v-if="showStatus" align="center" show-overflow-tooltip> 
            <template #default="scope">
              <span>{{ scope.row.autoLabel}}</span>
            </template>
          </el-table-column>
          <el-table-column  label="报销方名称" width="90" v-if="showStatus" align="center" show-overflow-tooltip> 
            <template #default="scope">
              <span>{{ scope.row.autoOtherName}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="amount" label="金额" width="90"> </el-table-column>
          <el-table-column prop="tax" label="税额" width="80"> </el-table-column>
          <el-table-column prop="taxAmount" label="价税合计" width="90"> </el-table-column>
          <el-table-column  label="所属账期" width="80"> 
            <template #default="scope">
              <span>{{ scope.row.ownershipPeriod}}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="150" v-if="showStatus" align="center" fixed="right"> 
            <template #default="scope">
              <el-button link size="small" @click="setLabel(scope.row)">单据标注</el-button>
              <el-button link size="small" @click="checkDetail(scope.row)">明细</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="font-size:13px;margin-top:16px;float: left;">
          汇总张数:<span style="font-weight:600;margin-right:10px">{{invoiceCount.count}}</span> 
          汇总金额:<span style="font-weight:600;margin-right:10px">{{invoiceCount.sum_amount}}</span>  
          汇总税额:<span style="font-weight:600;margin-right:10px">{{invoiceCount.sum_tax}}</span>
          汇总价税合计:<span style="font-weight:600">{{invoiceCount.sum_total}}</span> 
        </div>
        <div class="pagination">
          <qzf-pagination v-show="invoiceCount.count>0" :total="invoiceCount.count" v-model:page="page" v-model:limit="limit" @pagination="changeLimit" />
        </div>
      </el-tab-pane>
      <el-tab-pane label="发票仓库" name="second" lazy>
        <invoiceWare :comId="listQuery.comId" :period="period" @success="getList" ref="invoiceWare"/>
      </el-tab-pane>
    </el-tabs>
    
  </el-drawer>
  <!-- 单据标注 -->
  <el-dialog :close-on-click-modal="false" destroy-on-close v-model="dialogVisible" title="单据标注" width="500px">
    <el-form size="small">
      <el-form-item label="支付方式：">
        <el-checkbox-group v-model="form.method" :max="1">
          <el-checkbox label="现金结算" value="现金结算" />
          <el-checkbox label="银行结算" value="银行结算" />
          <el-checkbox label="其他应付结算" value="其他应付结算" />
          <el-checkbox label="应付结算" value="应付结算" />
          <el-checkbox label="预付结算" value="预付结算" />
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="业务类型：" v-if="form.labelType == 'in'">
        <el-checkbox-group v-model="form.businessType" :max="1">
          <el-checkbox label="固定资产" value="固定资产" />
          <el-checkbox label="成本" value="成本" />
          <el-checkbox label="费用" value="费用" />
          <el-checkbox label="原材料" value="原材料" />
          <el-checkbox label="工程物资" value="工程物资" />
          <el-checkbox label="库存商品" value="库存商品" />
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="业务类型：" v-if="form.labelType == 'out'">
        <el-checkbox-group v-model="form.businessType" :max="1">
          <el-checkbox label="资产清理" value="资产清理" />
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="费用报销：" v-if="form.labelType == 'in'">
        <el-input v-model="form.autoOtherName" style="width:80%" :disabled="form.method.length != 0"></el-input><span class="hint">(人名)</span>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取消</el-button>
        <el-button size="small" type="primary" @click="setSure()">确定</el-button>
      </div>
    </template>
  </el-dialog>
  <!-- 添加无票收入 -->
  <el-dialog :close-on-click-modal="false" destroy-on-close v-model="dialogAddVisible" title="添加无票收入" width="1280px">
    <el-scrollbar :maxHeight="contentStyleObj" width="100%">
      <div class="name_box">
        <h5>无票收入<span></span></h5>
      </div>
      <div style="width: 95%;margin: 0 auto;padding-bottom: 40px;">
        <out ref="addInvoice" invoiceType="3" :comId="listQuery.comId" :period="period" @success="getList" :comType="comInfo.type"></out>
      </div>
    </el-scrollbar>
  </el-dialog>
  <!-- 添加其他费用 -->
  <el-dialog :close-on-click-modal="false" destroy-on-close v-model="dialogFeeVisible" title="添加其他费用" width="1280px">
    <el-scrollbar :maxHeight="contentStyleObj" width="100%">
      <div class="name_box">
        <h5 class="fee">其他费用<span class="fee"></span></h5>
      </div>
      <div style="width: 95%;margin: 0 auto;padding-bottom: 40px;">
        <addInInvoice ref="addIns" invoiceType="11" :comId="listQuery.comId" :period="period" @success="getList"></addInInvoice>
      </div>
    </el-scrollbar>
  </el-dialog>
  <!-- 发票明细 -->
  <invoiceParticulars ref="invoiceParticulars" />
</template>

<script>
import { invoiceDetailList,inInvoiceMigrateWare,getperiodStatus } from "@/api/invoice"
import { wbVoucherFxOther } from "@/api/export"
import { autoLabelSet,confirmAutomaticBill } from '@/api/automatic'
import out from "../../input/invoice/invoiceComponents/out.vue"
import invoiceWare from "../../autoHome/components/invoiceWare.vue"
import addInInvoice from "../../input/invoice/invoiceIn/ins.vue"
import invoiceParticulars from '../../autoHome/components/invoiceParticulars.vue'
export default {
  name: 'collectInvoiceDetail',
  components: {
    addInInvoice,
    out,
    invoiceWare,
    invoiceParticulars
  },
  props: {},
  data () {
    return {
      drawer:false,
      listQuery:{
        invoiceType:'',
        startTime:"",
        endTime:"",
        comId:0,
        authStatus:"",
        types:"all"
      },
      detailPeriod:'month',
      value1:[],
      loading:false,
      contentStyleObj:{},
      list:[],
      showList:[],
      invoiceCount:0,
      invoiceNum:0,
      period:"",
      page:1,
      limit:20,
      comName:"",
      pay:['现金结算','银行结算','其他应付结算','应付结算','预付结算'],
      ywList:['固定资产','成本','费用','原材料','工程物资','库存商品','资产清理'],
      typeOptions:[
        {
          value:'1',
          label:'增值税专用发票'
        },
        {
          value:'2',
          label:'增值税普通发票'
        },
        {
          value:'3',
          label:'农副产品发票(免税)(9%)'
        },
        {
          value:'4',
          label:'增值税电子专用发票'
        },
        {
          value:'5',
          label:'增值税电子普通发票'
        },
        {
          value:'6',
          label:'海关进口增值税专用缴款书'
        },
        {
          value:'7',
          label:'其他发票(可抵扣)'
        },
        {
          value:'8',
          label:'农副产品发票(免税)(10%)'
        },
        {
          value: "9",
          label: "无票收入"
        },
      ],
      showStatus:false,
      form:{
        ids:[],
        autoLabel:"",
        labelType:"",
        method:[],
        businessType:[],
        autoOtherName:""
      },
      option:[],
      selectValue:[],
      dialogVisible:false,
      sels:[],
      comInfo:{},
      orgId: this.$store.getters["user/user"].orgId,
      dialogAddVisible:false,
      activeName:"first",
      dialogFeeVisible:false,
      showTooltipStatus:false,
    }
  },
  computed: {
    // 是否全选
    isAllSelected() {
      if(this.list.length == 0){
        return false
      }
      return this.tableData.every(el => {
        return el.checked;
      });
    },
  },
  mounted () {
    this.contentStyleObj = this.$getHeight(290)
  },
  methods: {
    init(row,period,flag){
      this.comInfo = row
      this.showTooltipStatus = [3,6,7,8].includes(row.djqr_invoice_status) ? false : true
      this.period = period
      this.listQuery.comId = row.comId
      this.listQuery.startTime = period
      this.listQuery.endTime = period
      this.listQuery.types = 'all'
      this.detailPeriod = 'month'
      this.listQuery.invoiceType = ''
      this.value1=[this.listQuery.startTime,this.listQuery.endTime]
      this.comName = row.name
      this.drawer = true
      if(flag){
        this.showStatus = true
      }else{
        this.showStatus = [2014,3224,3576,2038,2273].includes(this.orgId)? true : false
      }
      this.getList()
    },
    getList(){
      this.loading = true
      invoiceDetailList(this.listQuery).then(res => {
        this.loading = false
        if(res.data.msg == "success"){
          this.list = res.data.data.list
          this.showList = this.$qzfCopy(this.list)
          this.invoiceCount = res.data.data.sumStat
          this.invoiceNum = res.data.data.invoiceNumStat
          this.changeLimit()
        }
      })
    },
    //多选
    handleSelectionChange(e){
      this.sels = e
    },
    //分页
    changeLimit(){
      let arr = this.list
      this.showList = arr ? arr.slice((this.page - 1) * this.limit, this.page * this.limit) : []
    },
    changeMonth(){
      this.listQuery.startTime = this.value1[0]
      this.listQuery.endTime = this.value1[1]
      this.getList()
    },
    changeDetailType(){
      this.page = 1
      this.getList()
    },
    changePeriod(){
      let year = this.period.slice(0,4)
      let month = this.period.slice(4,6)
      if(this.detailPeriod == 'month'){
        this.value1 = [year+month,year+month]
      }else if(this.detailPeriod == 'season'){
        if(month < '04'){
          this.value1 = [year+'01',year+'03']
        }else if(month < '07'){
          this.value1 = [year+'04',year+'06']
        }else if(month < '10'){
          this.value1 = [year+'07',year+'09']
        }else if(month > '09'){
          this.value1 = [year+'10',year+'12']
        }
      } else if(this.detailPeriod == 'year'){
        this.value1 = [year+'01',year+'12']
      }
      this.listQuery.startTime = this.value1[0]
      this.listQuery.endTime = this.value1[1]
      this.getList()
    },
    // 导出
    exportDetail(){
      let param = {
        outType:"excel",
        exportType:"all_invoice_info",
        period:this.period,
        query:this.listQuery
      }
      wbVoucherFxOther(param).then(res => {
        if(res.data.msg == 'success'){
          window.open(res.data.data.url)
        }
      })
    },
    //转移发票仓库
    moveWare(){
      if(this.sels.length == 0){
        this.$qzfMessage('请选择发票',1)
        return
      }
      this.loading1 = true
      // let status = false
      // this.selects.map(v=>{
      //   if(v.voucherNo){
      //     status = true
      //   }
      // })
      // if(status){
      //   this.$qzfMessage("请选择未生成凭证的发票", 1)
      //   return
      // }
      let ids = []
      let periods = []
      let str = ''
      this.sels.map(v=>{
        if(v.types == 'out'){
           str = '请选择进项发票进行转移到发票仓库！'
           return
        }
        ids.push(v.invoiceId)
        periods.push(v.ownershipPeriod)
      })
      if(str){
        this.$qzfMessage(str,1)
        return
      }

      getperiodStatus({
        periods:periods,
        comId:this.comInfo.comId
      }).then(res=>{
        if(res.data.data.settled){//判断是不是结账 未结账不能转
          this.$qzfMessage('本期已结账，不能转移到发票仓库中！',2)
          return
        }else{
          inInvoiceMigrateWare({
            ids:ids,
            type:'ware'
          }).then(res=>{
            this.loading1 = false
            if(res.data.msg == 'success'){
              this.$qzfMessage('迁移成功');
              this.getList();
              this.selects = []
              this.$refs.invoiceWare?.getList()
            }
          })
        }
      })

    },
    //设置
    setLabel(row){
      this.reset()
      let label= row.autoLabel.split(',');
      if(label.length>0){
        label.forEach((item,i) => {
          if(this.pay.includes(item)){
            this.form.method = label.slice(i,i+1)
          }else if(this.ywList.includes(item)){
            this.form.businessType = label.slice(i,i+1)
          }
        });
      }
      this.form.ids = [row.invoiceId]
      this.form.labelType = row.types
      this.form.autoOtherName = row.autoOtherName
      this.dialogVisible = true
    },
    reset(){
      this.form.autoLabel = ""
      this.form.method = [],
      this.form.businessType = []
      this.form.autoOtherName = ""
    },
    setSure(){
      this.form.autoLabel = (this.form.method.length && this.form.businessType.length)? `${this.form.method[0]},${this.form.businessType[0]}`: (this.form.method.length ? this.form.method[0] : (this.form.businessType.length ? this.form.businessType[0] : ''));
      autoLabelSet(this.form).then(res => {
        if(res.data.msg == 'success'){
          this.$qzfMessage("设置成功")
          this.dialogVisible = false
          this.getList()
        }
      })
    },
    //批量设置
    batchSet(){
      if(this.sels.length == 0){
        this.$qzfMessage('请选择发票',1)
        return
      }
      let str = ''
      this.sels.map(v=>{
        if(v.types != this.sels[0].types){
          str = '进销项不可同时选择'
          return
        }
      })
      if(str){
        this.$qzfMessage(str,1)
        return
      }
      let arr = []
      this.sels.map(v=>{
        arr.push(v.invoiceId)
      })
      this.reset()
      if(this.sels.length == 1){
        let label= this.sels[0].autoLabel.split(',');
        if(label.length>0){
          label.forEach((item,i) => {
            if(this.pay.includes(item)){
              this.form.method = label.slice(i,i+1)
            }else if(this.ywList.includes(item)){
              this.form.businessType = label.slice(i,i+1)
            }
          });
        }
      }
      this.form.ids = arr
      this.form.labelType = this.sels[0].types
      this.dialogVisible = true
    },
    batchConfirm(){
      confirmAutomaticBill([{
        comId:this.listQuery.comId,
        period:this.period,
        type:'invoice'
      }]).then(res=>{
        if(res.data.msg == 'success'){
          this.$qzfMessage('成功确认');
          this.getList()
          this.$emit('success')
        }
      })
    },
    addNone(){
      this.getData('none')
      this.dialogAddVisible = true
    },
    addFee(){
      this.getData()
      this.dialogFeeVisible = true
      this.$nextTick(()=>{
        this.$refs.addIns.init()
      })
    },
    getData(type){
      if(type == 'none'){
        this.$store.dispatch('commons/getTaxCategoryList', this.listQuery.comId)
      }else{
        this.$store.dispatch("commons/getBusinessType",this.listQuery.comId)
      }
      this.$store.dispatch('commons/getCorp', this.listQuery.comId)
      this.$store.dispatch('commons/getSubjects', this.listQuery.comId)
      this.$store.dispatch('commons/getInventory', this.listQuery.comId)
      this.$store.dispatch('commons/getCodes', this.listQuery.comId)
    },
    //查询发票明细
    checkDetail(row){
      this.$refs.invoiceParticulars.init(row,this.listQuery.comId)
    }
  }
}
</script>

<style scoped lang="scss">
.hint{
  margin-left: 5px;
}
.name_box {
  margin: 0 auto;
  text-align: center;
  margin-bottom: 10px;
  h5 {
    display: inline-block;
    margin: 0 auto;
    font-size: 20px;
    color: #d56400;
    text-align: center;
    border-bottom: 4px double #d56400;
    line-height: 40px;
    position: relative;
    padding: 0 28px 0 4px;
    font-weight: 400;
    span {
      position: absolute;
      top: 13px;
      right: 3px;
      width: 0;
      height: 0;
      border-bottom: 16px solid #d56400;
      border-left: 16px solid transparent;
    }
  }
}
.fee{
  color: #008000 !important;
  border-bottom-color: #008000 !important;
}
.top_select{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

</style>
