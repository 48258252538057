<template>
  <div>
    <div style="text-align: right;margin: 10px 0;">
      <el-button size="small" type="success" @click="print()" icon="Folder" :loading="btnLoading">打印</el-button>
    </div>
    <el-table show-summary="true" size="small" :data="list" style="width: 100%" :height="contentStyleObj" border ref="table">
      <!-- <el-table-column  type="selection" width="35" align="center"></el-table-column> -->
      <el-table-column label="社保流水号码" min-width="240">
        <template #default="scope">
          <span>{{ scope.row.socialsecurity_serial_number }}</span>
        </template>
      </el-table-column>
      <el-table-column label="社保经办机构" min-width="230">
        <template #default="scope">
          <span>{{ scope.row.socialsecurity_agency }}</span>
        </template>
      </el-table-column>
      <el-table-column label="社保编码" min-width="120">
        <template #default="scope">
          <span>{{ scope.row.socialsecurity_code }}</span>
        </template>
      </el-table-column>
      <el-table-column label="征收项目" min-width="120">
        <template #default="scope">
          <span>{{ scope.row.collection_project }}</span>
        </template>
      </el-table-column>
      <el-table-column label="征收品目" min-width="200">
        <template #default="scope">
          <span>{{ scope.row.collection_items }}</span>
        </template>
      </el-table-column>
      <el-table-column label="征收子目" min-width="120">
        <template #default="scope">
          <span>{{ scope.row.collection_sub }}</span>
        </template>
      </el-table-column>
      <el-table-column label="费款所属期起" align="center" width="110">
        <template #default="scope">
          <span>{{ scope.row.taxes_period_start }}</span>
        </template>
      </el-table-column>
      <el-table-column label="费款所属期止" align="center" width="110">
        <template #default="scope">
          <span>{{ scope.row.taxes_period_end }}</span>
        </template>
      </el-table-column>
      <el-table-column label="缴费基数" align="center" min-width="90">
        <template #default="scope">
          <span v-if="scope.row.edit">
            <el-input size="small" v-model="scope.row.payment_base"></el-input>
          </span>
          <span v-else>{{ scope.row.payment_base }}</span>
        </template>
      </el-table-column>
      <el-table-column label="缴费费率" align="center" width="90" >
        <template #default="scope">
          <span v-if="scope.row.edit">
            <el-input size="small" v-model="scope.row.payment_rate"></el-input>
          </span>
          <span v-else>{{ scope.row.payment_rate }}</span>
        </template>
      </el-table-column>
      <el-table-column label="应缴费额" prop="payable_amount" align="center" width="90">
        <template #default="scope">
          <span v-if="scope.row.edit">
            <el-input size="small" v-model="scope.row.payable_amount"></el-input>
          </span>
          <span v-else>{{ scope.row.payable_amount }}</span>
        </template>
      </el-table-column>
      <el-table-column label="缴费人数" align="center" width="90">
        <template #default="scope">
          <span v-if="scope.row.edit">
            <el-input size="small" v-model="scope.row.number_payers"></el-input>
          </span>
          <span v-else>{{ scope.row.number_payers }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="操作"
        min-width="100"
        fixed="right"
      >
        <template #default="scope">
          <el-button size="small" link v-if="scope.row.edit" @click="saveMain(scope.row)">保存</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { checkCjShebao ,saveSbOne} from '@/api/taxCalculation.js'
import { shebaoDetail } from '@/api/printSet'
export default {
  name: 'checkCjSheBao',

  data() {
    return {
      list:[],
      btnLoading:false
    };
  },
  props: {
    paramCheck:{
      default:{},
      type:Object
    },
  },
  created() {
    this.contentStyleObj= this.$getHeight(240)
    this.getList()
  },

  mounted() {
    
  },

  methods: {
    getList(){
      checkCjShebao(this.paramCheck).then(res=>{
        //console.log(res.data.data.list);
        if(res.data.data.list){
          this.list = res.data.data.list
        }
      })
    },
    print(){
      this.btnLoading = true
      shebaoDetail({query:this.paramCheck}).then(res=>{
        this.btnLoading = false
        if(res.data.msg == 'success'){
          window.open(res.data.data.url)
        }
      })
    },
    saveMain(row){
      if(isNaN(Number(row.payment_base)) || isNaN(Number(row.payment_rate)) || isNaN(Number(row.payable_amount)) || isNaN(Number(row.number_payers))){
        this.$qzfMessage('请输入正确的数据',1)
        return
      }
      row.number_payers = Number(row.number_payers)
      saveSbOne(row).then(res=>{
        if(res.data.msg == 'success'){
          this.$qzfMessage('保存成功')
          this.getList()
        }
      })
    }
  },
};
</script>
